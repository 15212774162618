<!--==================================================================+
| MODULE-COMPONENT: DISH SHOWCASE                                     |
+===================================================================-->

<template>
  <div v-if="module" class="container relative">
    <h1>{{ module.title }}</h1>
    <rich-text
      v-if="module.description"
      :document="module.description"
      class="subheading"
    />

    <!--==================================================================+
    | DISH FILTERS                                                        |
    +===================================================================-->
    <juit-tags-filter
      v-if="module.list"
      :list="module.list"
      :list-only="module.listOnly"
      :available-only="module.availableOnly"
      :show-filter="module.showFilter"
      :advanced-filters="module.showFilter"
      @filtered="products = $event"
    />

    <div class="dishes-wrapper relative">
      <!--==================================================================+
      | DISHES                                                              |
      +===================================================================-->
      <div class="tiled">
        <juit-dish-card
          v-for="(product, index) in products"
          :key="index"
          :product="product"
        />
      </div>

      <div
        v-if="module.cta"
        class="flex justify-center mt-4"
      >
        <juit-button :to="`/${$i18n.locale}/${module.cta?.slug}`">
          <span class="cta-btn-text">{{ module.cta?.text }}</span>
        </juit-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Dish, DishShowcase } from '../content'
  import { nutrition } from '../widgets/juit-nutrition-label.vue'
  import dishModal from '../modals/dish.vue'
  import juitTagsFilter from '../widgets/juit-tags-filter.vue'

  export default defineComponent({
    name: 'JuitDishShowcase',
    components: { juitTagsFilter },
    props: {
      module: {
        type: Object as PropType<DishShowcase>,
        required: true,
      },
    },
    data: () => ({
      products: [] as Dish[],
      nutrition: nutrition,
    }),

    methods: {
      // Trigger product modal instead of following product link
      productClicked(product: Dish) {
        const url = `/${this.$i18n.locale}/dishes/${product.slug}`
        this.$createModal(dishModal as any, { product, url }).then((url) => {
          if (url) this.$router.push(url)
        })
      },
    },
  })
</script>

<style scoped lang="pcss">
.dishes-wrapper {
  :deep() .tiled {
    @apply mt-0 -mx-2 md:-mx-2.5 flex-wrap;
    .tile {
      @apply mobile:w-1/2 mobile-sm:w-full;
    }
  }
}
</style>
